// import gsap from 'gsap';
import { useState, useEffect, useRef } from 'react';
import { Clone } from '../js/components/Clone';
import { GameHeader } from '../Pages/Game';
import Footer from './Global/Footer';
import MainContent from './Global/MainContent';
import { Video } from './Video';

const Countdown = ({ startCountdown, setStartCountdown, timeLeft, setGameStatus, optionData, eventData }) => {
  const countDown = useRef();
  const timerDelay = process.env.REACT_APP_COUNTDOWN ? process.env.REACT_APP_COUNTDOWN : 1000;

  const clonedRef = useRef(false);
  const doClone = () => {
    Clone('js-clone', ['ani-glitch-split-2'], ['ani-glitch-split-1']);
  };
  useEffect(() => {
    if (clonedRef.current) return;
    clonedRef.current = true;
    doClone();
  }, []);

  const [counter, setCounter] = useState(4);

  useEffect(() => {
    var interval;
    if (startCountdown) {
      interval = setInterval(function () {
        if (counter > 0) {
          setCounter(counter - 1);
          console.log('counter = ' + counter);
        }
        if (counter === 1) {
          clearInterval(interval);
          setStartCountdown(false);
        }
      }, timerDelay);
    }
    return () => {
      clearInterval(interval);
    };
  }, [counter, startCountdown, setStartCountdown, timerDelay]);

  return (
    <>
      <Video layer="bg" />
      <MainContent>
        <GameHeader
          timeLeft={timeLeft}
          setGameStatus={setGameStatus}
          startCountdown={startCountdown}
          eventData={eventData}
        />
        <div className="relative">
          <div className="ani-artifact-glitch-1"></div>
          <h1 className="text-h2 text-center font-extrabold">game starting!</h1>
          <div
            ref={countDown}
            className="section-countdown w-full h-[280px] mt-4 sm:mt-8 flex flex-col justify-center items-center leading-[0.8]"
          >
            <div className={`${counter === 4 ? 'block' : 'hidden'}`}>
              <p
                className="countdown-text text-[333px] drop-shadow-hotpink-sm text-shadow-hotpink-sky font-extrabold"
                data-content="3"
              >
                <span className="js-clone ani-glitch-split-1">3</span>
              </p>
            </div>
            <div className={`${counter === 3 ? 'block' : 'hidden'}`}>
              <p
                className="countdown-text text-[333px] drop-shadow-hotpink-sm text-shadow-hotpink-sky font-extrabold hidden"
                data-content="2"
              >
                <span className="js-clone ani-glitch-split-1">2</span>
              </p>
            </div>
            <div className={`${counter === 2 ? 'block' : 'hidden'}`}>
              <p
                className="countdown-text text-[333px] drop-shadow-hotpink-sm text-shadow-hotpink-sky font-extrabold hidden"
                data-content="1"
              >
                <span className="js-clone ani-glitch-split-1">1</span>
              </p>
            </div>
            <div className={`${counter === 1 ? 'block' : 'hidden'}`}>
              <p
                className="countdown-text text-[250px] drop-shadow-hotpink-sm text-shadow-hotpink-sky font-extrabold hidden"
                data-content="GO"
              >
                <span className="js-clone ani-glitch-split-1">GO</span>
              </p>
            </div>
          </div>
        </div>
        <Footer optionData={optionData} />
      </MainContent>
      <Video layer="fg" />
    </>
  );
};

export default Countdown;
