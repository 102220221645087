import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../Loader';
import { Button } from '../Button';
import { getAllEventData } from '../UpdatePlayerInfo';

export default function Footer({ optionData, joinLobby, eventData, setLobbyId, resetGame }) {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const callback = () => {
    setIsActive(false);
    return resetGame();
  };

  const [events, setEvents] = useState([]);

  const navigate = useNavigate();
  const handleOptionClick = (e) => {
    navigate(`${e.target.value}`);
    setIsActive(false);
  };

  useEffect(() => {
    async function fetchEventData() {
      try {
        const eventsData = await getAllEventData();

        setEvents(eventsData);
      } catch (e) {}
    }
    fetchEventData();
  }, []);

  const csvData = [
    eventData &&
      eventData.acf &&
      eventData.acf.scoreboard &&
      eventData.acf.scoreboard.map((score) => {
        return [score.player[0].acf.name, score.player[0].acf.email, `${score.score}ms`];
      }),
  ];

  csvData && csvData[0] && csvData[0].unshift(['name', 'email', 'score']);
  // console.log(eventData.acf.scoreboard);
  if (optionData && optionData.acf) {
    return (
      <footer className="k-footer w-full section">
        <div className="container flex items-end">
          <div className="w-1/3 text-lg">
            <svg className="footer-logo w-[123px] h-[52px]">
              <use xlinkHref="#brit-logo" />
            </svg>
          </div>
          <div className="w-1/3 text-lg">
            {optionData.acf.general && optionData.acf.general.company_url && (
              <p className="flex items justify-center">{optionData.acf.general.company_url}</p>
            )}
          </div>
          <div className="w-1/3 text-lg">
            <p className="flex items justify-end">
              {optionData.acf.general && optionData.acf.general.company_name && (
                <>
                  &copy;{new Date().getFullYear()} {optionData.acf.general.company_name}. All rights reserved.
                </>
              )}
            </p>
          </div>
        </div>
        <div className="w-[33%] section-admin fixed right-0 top-0 h-screen py-[7%] flex flex-row z-50">
          <div
            className={`${
              isActive ? 'translate-x-[0]' : 'translate-x-[calc(100%-32px)]'
            } w-full rounded-l-xl w-full bg-dark border border-white border-r-0 h-full flex items-center justify-center py-2 transition-all relative duration-300 ease-in-out-expo`}
          >
            <div
              className="cursor-pointer !absolute top-4 right-4 z-10 w-10 h-10 clone-parent justify-center items-center"
              onClick={handleClick}
            >
              <svg className="js-cross-clone ani-glitch-split-1 animation-duration-5s w-6 h-6 ">
                <use xlinkHref="#cross" />
              </svg>
            </div>
            <div className="cursor-pointer flex flex-col h-full items-center justify-between" onClick={handleClick}>
              <div className="flex flex-col items-center justify-center">
                <div className="w-[34px] h-[34px] flex items-center justify-center">
                  <svg className="w-[14px] h-[14px]">
                    <use xlinkHref="#hotdog" />
                  </svg>
                </div>
                <p className="mt-2 font-bold uppercase text-vertical tracking-tight tracking-widest">
                  administration Personnel only
                </p>
              </div>
              <svg className="w-[12px] h-[182px] mb-[70px] mt-8">
                <use xlinkHref="#admin-stripes" />
              </svg>
            </div>
            <div className="w-[50vw] flex items-center justify-center flex-col text-center space-y-14">
              <div className="max-w-3xl">
                <h2 className="text-xl uppercase font-bold">current game</h2>
                {eventData && eventData.acf && <p className="text-md mt-4">Reset {eventData.acf.name} game</p>}
                <p>
                  <Button
                    callback={callback}
                    glitch="1"
                    size="xl"
                    classes="min-w-[80%] mx-auto mt-4"
                    title="reset game"
                  />
                </p>
                {csvData && eventData && eventData.acf && eventData.acf.scoreboard && (
                  <>
                    <p className="text-md mt-8">Download player scores and details</p>
                    <p>
                      <Button
                        size="xl"
                        classes="min-w-[80%] mx-auto mt-4"
                        title="download"
                        csvFile={[`${eventData.acf.name.replace(/ /g, '_')}_Scoreboard.csv`]}
                        csvData={csvData[0]}
                      />
                    </p>
                  </>
                )}
              </div>
              <div className="flex max-w-[300px] items-center justify-center gap-4">
                <div className="w-[100px] bg-white h-[1px]"></div>
                <p className="text-md">or</p>
                <div className="w-[100px] bg-white h-[1px]"></div>
              </div>
              <div className="max-w-3xl">
                <h2 className="text-xl uppercase font-bold">load new game</h2>
                <p className="text-md">Select an event from the list below</p>
                <select
                  className="rounded bg-white text-dark text-lg mt-4"
                  onChange={handleOptionClick}
                  defaultValue={'DEFAULT'}
                >
                  <option value="DEFAULT" disabled>
                    Select an Event
                  </option>
                  {events.map((l, key) => {
                    return (
                      <option key={key} value={`/${l.id}`}>
                        {l.acf.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  } else {
    return <Loader />;
  }
}
