import { useState, useEffect } from 'react';
import Footer from '../Components/Global/Footer';
import MainContent from '../Components/Global/MainContent';
import TechHeading from '../Components/TechHeading';
import { Video } from '../Components/Video';
import { Loader } from '../Components/Loader';
import QRCode from 'react-qr-code';
import Logo from '../img/logo.png';
import QR from '../img/qr-background.png';

const Lobby = ({ players, optionData, eventData, resetGame, lobbyId }) => {
  const headingClasses =
    'uppercase text-2xl !leading-[0.9] tracking-tight font-medium mt-2 whitespace-nowrap text-ellipsis overflow-hidden';
  const qrTextClasses = 'w-[100%] right-[1%] uppercase 2xl:text-[210%] font-bold absolute z-[2] text-center';

  const [howManyPlayers, setHowManyPlayers] = useState(1);

  useEffect(() => {
    const p = players.length;
    console.log(p);
    setHowManyPlayers(p);
    return () => {};
  }, [players]);

  return (
    <>
      <Video layer="bg" />
      <MainContent>
        <GameHeader eventData={eventData} />
        <div className="section-player-list container flex flex-col items-center gap-3">
          <div className="flex gap-10 w-full">
            <div className={`${howManyPlayers === 3 ? 'hidden ' : ''}w-1/6 text-dark min-w-[200px] max-w-[280px]`}>
              <div className="aspect-[635/856] relative">
                <img className="w-full h-auto absolute left-0 top-0 z-[1]" src={QR} alt="logo with QR code" />
                <p className={`${qrTextClasses} top-[12%]`}>
                  <span>scan to play</span>
                </p>
                <figure className="js-qr-container w-[70%] aspect-square absolute right-[16%] top-[24%] block z-[2] flex items-center justify-center">
                  <QRCode
                    className="absolute w-full h-full inset-0"
                    value={`https://app.britcodehacker.co.uk/${lobbyId}`}
                  />
                </figure>
                <p className={`${qrTextClasses} bottom-[12%]`}>
                  <span>hack and win</span>
                </p>
              </div>
            </div>
            <div className="mx-auto">
              {/*
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            PLAYER 1
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
             */}
              <article className="player-list-item w-[1412px] h-[254px] relative">
                <svg className="absolute inset-0 w-full h-full z-[0]">
                  <use xlinkHref="#player-ui-bg" />
                </svg>
                <svg className="absolute inset-0 w-full h-full z-[1] drop-shadow-amethyst-lg">
                  <use xlinkHref="#player-ui" />
                </svg>
                <div className="absolute inset-0 w-full h-full z-[2] flex justify-between">
                  <header className="px-6 py-4 w-[27.7%]">
                    <TechHeading
                      text="player 1"
                      color="bg-amethyst"
                      clipPostion="top-right"
                      classes="drop-shadow-amethyst-sm"
                    />
                    <p className={headingClasses}>{players[0] ? players[0].name : 'Waiting...'}</p>
                  </header>
                  <div className="w-[71%] flex items-center">
                    <div className="pl-[14%] w-full">
                      <TechHeading
                        text="status"
                        color="bg-amethyst"
                        clipPostion="top-right"
                        classes="drop-shadow-amethyst-sm"
                      />
                      <div className="text-amethyst">
                        <p
                          className={`${
                            players[0] && players[0].status === 'ready' ? '' : 'text-stroke '
                          }text-h2 font-bold drop-shadow-amethyst-sm`}
                        >
                          {players[0] ? (players[0].status === 'not-ready' ? 'joined' : players[0].status) : 'Waiting'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              {/*
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            PLAYER 2
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
             */}
              <article className="player-list-item w-[1412px] h-[254px] relative">
                <svg className="absolute inset-0 w-full h-full z-[0]">
                  <use xlinkHref="#player-ui-bg" />
                </svg>
                <svg className="absolute inset-0 w-full h-full z-[1] drop-shadow-flamingo-lg">
                  <use xlinkHref="#player-ui" />
                </svg>
                <div className="absolute inset-0 w-full h-full z-[2] flex justify-between">
                  <header className="p-6 w-[27.7%]">
                    <TechHeading
                      text="player 2"
                      color="bg-flamingo"
                      clipPostion="top-right"
                      classes="drop-shadow-flamingo-sm"
                    />
                    <p className={headingClasses}>{players[1] ? players[1].name : 'Waiting...'}</p>
                  </header>
                  <div className="w-[71%] flex items-center">
                    <div className="pl-[14%] w-full">
                      <TechHeading
                        text="status"
                        color="bg-flamingo"
                        clipPostion="top-right"
                        classes="drop-shadow-flamingo-sm"
                      />
                      <div className="text-flamingo">
                        <p
                          className={`${
                            players[1] && players[1].status === 'ready' ? '' : 'text-stroke '
                          }text-h2 font-bold drop-shadow-flamingo-sm`}
                        >
                          {players[1] ? (players[1].status === 'not-ready' ? 'joined' : players[1].status) : 'Waiting'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              {/*
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            PLAYER 3
            !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
             */}
              <article className="player-list-item w-[1412px] h-[254px] relative">
                <svg className="absolute inset-0 w-full h-full z-[0]">
                  <use xlinkHref="#player-ui-bg" />
                </svg>
                <svg className="absolute inset-0 w-full h-full z-[1] drop-shadow-carnation-lg">
                  <use xlinkHref="#player-ui" />
                </svg>
                <div className="absolute inset-0 w-full h-full z-[2] flex justify-between">
                  <header className="p-6 w-[27.7%]">
                    <TechHeading
                      text="player 3"
                      color="bg-carnation"
                      clipPostion="top-right"
                      classes="drop-shadow-carnation-sm"
                    />
                    <p className={headingClasses}>{players[2] ? players[2].name : 'Waiting...'}</p>
                  </header>
                  <div className="w-[71%] flex items-center">
                    <div className="pl-[14%] w-full">
                      <TechHeading
                        text="status"
                        color="bg-carnation"
                        clipPostion="top-right"
                        classes="drop-shadow-carnation-sm"
                      />
                      <div className="text-carnation">
                        <p
                          className={`${
                            players[2] && players[2].status === 'ready' ? '' : 'text-stroke '
                          }text-h2 font-bold drop-shadow-carnation-sm`}
                        >
                          {players[2] ? (players[2].status === 'not-ready' ? 'joined' : players[2].status) : 'Waiting'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <Footer eventData={eventData} optionData={optionData} resetGame={resetGame} />
      </MainContent>
      <Video layer="fg" />
    </>
  );
};

const GameHeader = ({ eventData }) => {
  if (eventData.acf) {
    return (
      <header className="container flex">
        <figure className="section-sm !w-[153px] shrink-0 mr-20 grow">
          <img className="w-full h-auto" src={Logo} alt="logo" />
        </figure>
        <div>
          <TechHeading text="time remaining" color="bg-white" clipPostion="bottom-right" />
          <div className="mt-4">
            <p className="!font-mono font-light text-h3 text-current">02:00:00</p>
          </div>
        </div>

        <div className="grow flex flex-col justify-center text-right section-sm">
          <div>
            {eventData && eventData.acf.name && <p className="text-h5 font-extrabold">{eventData.acf.name}</p>}
            {eventData && eventData.acf.details && (
              <p className="text-bold text-xl font-bold">{eventData.acf.details}</p>
            )}
          </div>
        </div>
      </header>
    );
  } else {
    <Loader />;
  }
};

export default Lobby;
