import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import FourOhFour from './FourOhFour';
import Idle from './Idle';
import { socket, SocketContext } from '../Providers/socketProvider';
import SocketWrapper from '../Wrappers/SocketWrapper';
import GameWrapper from '../Wrappers/GameWrapper';
import GameSelect from './GameSelect';
import { getAllEventData } from '../Components/UpdatePlayerInfo';
import { Button } from '../Components/Button';
import SvgIcon from '../Components/Global/SvgIcon';
import Cookies from 'universal-cookie';

import '../scss/theme.scss';

function App() {
  // const [loggedIn, setLoggedIn] = useState(false);
  const user = process.env.REACT_APP_ADMIN_USER ? process.env.REACT_APP_ADMIN_USER : 'h4ck_br1t_usr';
  const pw = process.env.REACT_APP_ADMIN_PW ? process.env.REACT_APP_ADMIN_PW : 'h4ck_br1t_pw';
  const cookies = new Cookies();

  const searchParams = new URLSearchParams(window.location.search);
  const bypass = searchParams.get('bypass');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e.target.user.value) {
      alert('user is required');
    } else if (!e.target.user.value) {
      alert('Valid user is required');
    } else if (!e.target.password.value) {
      alert('Password is required');
    } else if (e.target.user.value === user && e.target.password.value === pw) {
      // setLoggedIn(true);
      cookies.set('isLoggedIn', 'true', { path: '/' });
      e.target.user.value = '';
      e.target.password.value = '';
    } else {
      alert('Wrong user or password combination');
    }
  };

  useEffect(() => {
    console.log(bypass);
    if (bypass === 'ABC123') {
      console.log('hello');
      cookies.set('isLoggedIn', 'true', { path: '/' });
    }
  }, [bypass, cookies]);

  const [eventsData, setEventsData] = useState([]);
  useEffect(() => {
    async function fetchEventData() {
      try {
        const e = await getAllEventData();
        setEventsData(e);
      } catch (e) {
        console.log(e);
      }
    }
    fetchEventData();
  }, []);

  if (cookies.get('isLoggedIn') === 'true') {
    return (
      <SocketContext.Provider value={socket}>
        <BrowserRouter>
          <SvgIcon />
          <Routes>
            <Route path="/idle" element={<Idle />} />
            <Route exact path="/" element={<GameSelect eventsData={eventsData} />} />
            <Route exact path="/:id" element={<SocketWrapper component={(props) => <GameWrapper {...props} />} />} />
            {/* <Route path="*" element={<FourOhFour />} /> */}
          </Routes>
        </BrowserRouter>
      </SocketContext.Provider>
    );
  } else {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <div className="flex p-3 border-mid drop-shadow-white-md bg-dark">
          <p className="mt-2 font-bold uppercase text-vertical text-sm tracking-widest mr-4 text-mid">
            administration Personnel only
          </p>
          <form className="w-[320px] flex items-center pr-3 pb-2" onSubmit={handleSubmit}>
            <div className="space-y-4 w-full">
              <fieldset>
                <input type="text" name="user" />
                <label htmlFor="user">User</label>
              </fieldset>
              <fieldset>
                <input type="password" name="password" />
                <label htmlFor="password">password</label>
              </fieldset>
              <div>
                <Button size="xl" glitch="2" classes="w-full mt-4" title="enter" />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default App;
