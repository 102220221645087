import React from 'react';

export default function TechHeading({
  text,
  color = 'bg-current',
  clipPostion = 'top-right',
  classes = null,
  inlineStyle,
}) {
  return (
    <div style={inlineStyle} className={`inline-flex ${classes && ' ' + classes}`}>
      <p className={`clip-${clipPostion} ${color} pl-2 pr-5`}>
        <span className="text-md uppercase tracking-widest text-dark font-bold !leading-tight">{text}</span>
      </p>
    </div>
  );
}
