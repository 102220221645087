import React from 'react';
import Lobby from '../Pages/Lobby';
import { Game } from '../Pages/Game';

import Footer from '../Components/Global/Footer';
import MainContent from '../Components/Global/MainContent';
import { Video } from '../Components/Video';

import Complete from '../Pages/Complete';
import Idle from '../Pages/Idle';

const GameWrapper = ({
  players,
  host,
  errorMessage,
  joinLobby,
  leaveLobby,
  removePlayer,
  gameStatus,
  lobbyId,
  name,
  setName,
  email,
  setEmail,
  company,
  setCompany,
  updatePlayerstatus,
  startGame,
  startCountdown,
  setStartCountdown,
  timeLeft,
  currentNumbers,
  modalOpen,
  optionData,
  eventData,
  setModalOpen,
  setErrorMessage,
  nextLevel,
  setGameStatus,
  scoreTimer,
  incorrectAnswer,
  currentLevels,
  ranks,
  setLobbyId,
  stages,
  gameTimerRef,
  resetGame,
  index,
  setIndex,
}) => {
  const gameComponents = () => {
    switch (gameStatus) {
      case 'not-started':
        return (
          <Idle
            lobbyId={lobbyId}
            optionData={optionData}
            eventData={eventData}
            resetGame={resetGame}
            index={index}
            setIndex={setIndex}
          />
        );
      case 'lobby':
        return (
          <Lobby
            lobbyId={lobbyId}
            leaveLobby={leaveLobby}
            removePlayer={removePlayer}
            players={players}
            host={host}
            updatePlayerstatus={updatePlayerstatus}
            startGame={startGame}
            setErrorMessage={setErrorMessage}
            setModalOpen={setModalOpen}
            optionData={optionData}
            eventData={eventData}
            resetGame={resetGame}
          />
        );
      case 'in-progress':
        return (
          <Game
            startCountdown={startCountdown}
            setStartCountdown={setStartCountdown}
            timeLeft={timeLeft}
            currentNumbers={currentNumbers}
            nextLevel={nextLevel}
            players={players}
            setGameStatus={setGameStatus}
            scoreTimer={scoreTimer}
            incorrectAnswer={incorrectAnswer}
            lobbyId={lobbyId}
            optionData={optionData}
            eventData={eventData}
            currentLevels={currentLevels}
            ranks={ranks}
            stages={stages}
            gameTimerRef={gameTimerRef}
            resetGame={resetGame}
          />
        );
      case 'complete':
        return <Complete scoreTimer={scoreTimer} timeLeft={timeLeft} name={name} />;
      default:
        return (
          <>
            <Video layer="bg" />
            <MainContent>
              <Idle
                joinLobby={joinLobby}
                email={email}
                setEmail={setEmail}
                name={name}
                setName={setName}
                company={company}
                setCompany={setCompany}
                index={index}
                setIndex={setIndex}
                eventData={eventData}
              />
              <Footer
                optionData={optionData}
                eventData={eventData}
                joinLobby={joinLobby}
                setLobbyId={setLobbyId}
                resetGame={resetGame}
              />
            </MainContent>
            <Video layer="fg" />
          </>
        );
    }
  };

  return <>{gameComponents()}</>;
};

export default GameWrapper;
