import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { SocketContext } from '../Providers/socketProvider';
import { useParams } from 'react-router-dom';
import useTimer from '../Hooks/useTimer';
import { makeid } from '../functions/makeId';
import { getEventData, getOptionsData } from '../Components/UpdatePlayerInfo';

const SocketWrapper = ({ component }) => {
  const socket = useContext(SocketContext);
  const { id } = useParams();

  const [index, setIndex] = useState(0);
  const [name, setName] = useState('');
  const [, setTv] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [players, setPlayers] = useState([]);
  const [host, setHost] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    heading: 'this app uses essential cookies',
    copy: 'we may also use non-essential cookies with your consent.',
    close: false,
    button: { text: 'ALLOW', link: null },
  });
  const [lobbyId, setLobbyId] = useState(null);
  const [gameStatus, setGameStatus] = useState('not-started');
  const [timeLeft, setTimeLeft] = useState(0);
  const [startCountdown, setStartCountdown] = useState(false);
  const [currentNumbers, setCurrentNumbers] = useState([]);
  const [modalOpen, setModalOpen] = useState(true);
  const [currentLevels, setCurrentLevels] = useState([
    {
      playerNumber: 1,
      level: 0,
    },
    {
      playerNumber: 2,
      level: 0,
    },
    {
      playerNumber: 3,
      level: 0,
    },
  ]);
  const [stages, setStages] = useState([
    {
      playerNumber: 1,
      stage: 0,
    },
    {
      playerNumber: 2,
      stage: 0,
    },
    {
      playerNumber: 3,
      stage: 0,
    },
  ]);

  const [ranks, setRanks] = useState([
    {
      playerNumber: 1,
      rank: null,
      time: null,
    },
    {
      playerNumber: 2,
      rank: null,
      time: null,
    },
    {
      playerNumber: 3,
      rank: null,
      time: null,
    },
  ]);
  const gameTimerRef = useRef();
  const gameStatusRef = useRef();
  gameStatusRef.current = gameStatus;

  const currentLevelsRef = useRef();
  currentLevelsRef.current = currentLevels;
  const stagesRef = useRef();
  stagesRef.current = stages;
  const ranksRef = useRef();
  ranksRef.current = ranks;
  const currentNumbersRef = useRef();
  currentNumbersRef.current = currentNumbers;

  const scoreTimer = useTimer();

  const resetState = useCallback(() => {
    setGameStatus('not-started');
    setName('');
    setPlayers([]);
    setHost(null);
    setLobbyId(null);
    setRanks([]);
    setCurrentLevels([]);
    setStages([]);
    setIndex(0);
  }, []);

  useEffect(() => {
    if (!lobbyId) {
      setLobbyId(`${id}-${makeid(6)}`);
    }
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');
    console.log('GO TO LOBBY - ' + lobbyId);
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');
  }, [id, lobbyId]);

  const [optionData, setOptionData] = useState([]);
  const [eventData, setEventData] = useState([]);
  useEffect(() => {
    async function fetchEventData() {
      try {
        const eventData = await getEventData(id);
        const optionApiData = await getOptionsData();

        setEventData(eventData);
        setOptionData(optionApiData[0]);
        console.log(eventData);
        console.log(optionApiData[0]);
      } catch (e) {
        console.log(e);
      }
    }
    if (gameStatus === 'not-started') {
      fetchEventData();
    }
  }, [gameStatus, id, lobbyId]);

  useEffect(() => {
    if (scoreTimer.elapsedTime > 120000) {
      scoreTimer.handlePause();

      const endGameRanks = ranks.map((rank) => {
        if (rank.rank === null) {
          return {
            playerNumber: rank.playerNumber,
            rank: 'lost',
            time: 9999999999,
          };
        } else {
          return rank;
        }
      });

      setRanks(endGameRanks);
      socket.emit('allGamesCompleted', { lobbyId });
    }
  }, [lobbyId, ranks, scoreTimer, socket]);

  const joinLobby = useCallback(
    (name = null, email = null, company = null, type = 'tv') => {
      setErrorMessage(null);

      if (lobbyId !== null) {
        socket.emit('joinLobby', { name, lobbyId, type });
      }
    },
    [lobbyId, socket]
  );

  const leaveLobby = (lobbyId) => {
    socket.emit('leaveLobby', { lobbyId });
  };

  const removePlayer = (lobbyId, playerId) => {
    socket.emit('removePlayer', { lobbyId, playerId });
  };

  const updatePlayerstatus = (lobbyId, playerId, status) => {
    socket.emit('playerStatusUpdated', { lobbyId, playerId, status });
  };

  const startGame = (lobbyId) => {
    socket.emit('startGame', { lobbyId });
  };

  const incorrectAnswer = ({ lobbyId, currentLevel, stage }) => {
    socket.emit('incorrectAnswer', { lobbyId });
  };

  const resetGame = () => {
    leaveLobby(lobbyId);
    setGameStatus('not-started');
    setName('');
    setPlayers([]);
    setHost(null);
    setLobbyId(null);
    setRanks([]);
    setCurrentLevels([]);
    setStages([]);
  };

  const gameincomplete = useCallback(() => {
    scoreTimer.handlePause();
    setGameStatus('complete');
    socket.emit('gameComplete', { lobbyId, playerId: socket.io.engine.id, time: 9999999999 });
  }, [scoreTimer, setGameStatus, socket, lobbyId]);

  useEffect(() => {
    if (socket) {
      setGameStatus('not-started');
      if (lobbyId !== null) {
        joinLobby({ lobbyId, type: 'tv' });
      }
    }
  }, [joinLobby, lobbyId, socket, socket.connected]);

  useEffect(() => {
    socket.on('lobbyFull', () => {
      setErrorMessage({ heading: 'The lobby is full.', copy: null, button: { text: 'OK', link: '/' }, close: false });
      setModalOpen(true);
    });

    // Handle new player joining the lobby
    socket.on('playerJoined', ({ playerId, player, players, host }) => {
      console.log(`-- socket ${playerId} joined the lobby --`);
      const playerInGame = players.some((p) => p.status === 'in-game');
      if (playerInGame) {
        setErrorMessage({
          heading: 'The game has already started.',
          copy: null,
          button: { text: 'OK', link: '/' },
          close: false,
        });
      } else {
        setPlayers(players);
        setHost(host);

        setGameStatus('lobby');

        if (player.id === socket.io.engine.id) {
          setName(player.name);
        } else {
          if (players.length > 1) {
            const updatedPlayers = players.map((player, index) => ({
              ...player,
              playerNumber: index + 1,
            }));
            setPlayers(updatedPlayers);
          }
        }
      }
    });

    socket.on('tvJoined', ({ players, tv }) => {
      console.log(`tv joined ${lobbyId}`);
      setGameStatus('not-started');
      setPlayers(players);
      setTv(tv);
    });

    socket.on('playersUpdated', (players) => {
      setPlayers(players);
    });

    socket.on('startTimer', ({ duration }) => {
      const d = process.env.REACT_APP_GAMETIME ? process.env.REACT_APP_GAMETIME : duration;
      setTimeLeft(d);
      setGameStatus('in-progress');

      const playerStartingLevels = players.map((p) => {
        return {
          playerNumber: p.playerNumber,
          level: 0,
        };
      });

      const playerStartingStages = players.map((p) => {
        return {
          playerNumber: p.playerNumber,
          stage: 0,
        };
      });

      const playerStartingRanks = players.map((p) => {
        return {
          playerNumber: p.playerNumber,
          rank: null,
          time: null,
        };
      });

      setCurrentLevels([...playerStartingLevels]);
      setStages([...playerStartingStages]);
      setRanks([...playerStartingRanks]);

      setStartCountdown(true);
    });

    socket.on('numbersGenerated', ({ sequences }) => {
      setCurrentNumbers(sequences);
    });

    socket.on('correctAnswer', ({ playerNumber, stage }) => {
      stages.forEach((s, index) => {
        if (s.playerNumber === playerNumber) {
          const nextStage = stage + 1;

          setStages((prevStages) => {
            const updatedStages = [...prevStages];
            updatedStages[index] = {
              playerNumber,
              stage: nextStage,
            };
            return updatedStages; // Return the updated array
          });
        }
      });
    });

    socket.on('incorrectAnswer', ({ playerNumber, stage }) => {
      stages.forEach((s, index) => {
        if (s.playerNumber === playerNumber) {
          setStages((prevStages) => {
            const updatedStages = [...prevStages];
            updatedStages[index] = {
              playerNumber,
              stage: 0,
            };
            return updatedStages; // Return the updated array
          });
        }
      });
    });
    socket.on('numbersUpdated', ({ currentLevel, numbers }) => {
      const indexStages = stages.findIndex((s) => s.playerNumber === numbers.playerNumber);
      const indexLevels = currentLevels.findIndex((s) => s.playerNumber === numbers.playerNumber);
      const indexNumbers = currentNumbers.findIndex((s) => s.playerNumber === numbers.playerNumber);

      setStages((prevStages) => {
        const updatedStages = [...prevStages];
        updatedStages[indexStages] = {
          playerNumber: numbers.playerNumber,
          stage: 0,
        };
        return updatedStages;
      });

      setCurrentLevels((prevLevels) => {
        const updatedLevels = [...prevLevels];
        updatedLevels[indexLevels] = {
          playerNumber: numbers.playerNumber,
          level: currentLevel,
        };
        return updatedLevels;
      });

      setCurrentNumbers((prevNumbers) => {
        const updatedNumbers = [...prevNumbers];
        updatedNumbers[indexNumbers] = {
          playerNumber: numbers.playerNumber,
          numbers: numbers.numbers,
        };
        return updatedNumbers;
      });
    });

    socket.on('gameCompleted', ({ rank, playerNumber, time }) => {
      const indexRanks = ranks.findIndex((s) => s.playerNumber === playerNumber);

      setRanks((prevRanks) => {
        const updatedRanks = [...prevRanks];
        updatedRanks[indexRanks] = {
          playerNumber: playerNumber,
          rank,
          time,
        };
        if (updatedRanks.every((r) => r.rank !== null)) {
          gameTimerRef.current.pause();

          socket.emit('allGamesCompleted', { lobbyId });
          setTimeout(() => {
            socket.emit('leaveLobby', { lobbyId });
            resetState();
          }, 10000);
        }
        return updatedRanks;
      });
    });

    socket.on('playerLeft', (data) => {
      // Update player numbers
      const playerIndex = players.findIndex((player) => player.id === data.playerId);
      // const currentPlayerNumber = players[playerIndex].playerNumber;

      if (gameStatusRef.current !== 'in-progress') {
        const updatedPlayers = data.players.map((player, index) => ({
          ...player,
          playerNumber: index + 1,
        }));
        console.log(updatedPlayers);
        setPlayers(updatedPlayers);
      } else {
        console.log(data.players);
        setPlayers(data.players);
      }

      // Update currentLevels, stages, and ranks
      const newCurrentLevels = [...currentLevelsRef.current];
      const newCurrentNumbers = [...currentNumbersRef.current];
      const newStages = [...stagesRef.current];
      const newRanks = [...ranksRef.current];

      console.log(playerIndex);
      newCurrentLevels.splice(playerIndex, 1);
      newCurrentNumbers.splice(playerIndex, 1);
      newStages.splice(playerIndex, 1);
      newRanks.splice(playerIndex, 1);

      console.log(newCurrentLevels);
      console.log(newCurrentNumbers);
      console.log(newStages);
      console.log(newRanks);

      setCurrentLevels(newCurrentLevels);
      setCurrentNumbers(newCurrentNumbers);
      setStages(newStages);
      setRanks(newRanks);

      // Reset state if there are no players
      if (data.players.length === 0) {
        leaveLobby(lobbyId);
        setGameStatus('not-started');
        setName('');
        setPlayers([]);
        setHost(null);
        setLobbyId(null);
        setCurrentLevels([
          {
            playerNumber: 1,
            stage: 0,
          },
          {
            playerNumber: 2,
            stage: 0,
          },
          {
            playerNumber: 3,
            stage: 0,
          },
        ]);
        setStages([
          {
            playerNumber: 1,
            stage: 0,
          },
          {
            playerNumber: 2,
            stage: 0,
          },
          {
            playerNumber: 3,
            stage: 0,
          },
        ]);
        setRanks([
          {
            playerNumber: 1,
            rank: null,
            time: null,
          },
          {
            playerNumber: 2,
            rank: null,
            time: null,
          },
          {
            playerNumber: 3,
            rank: null,
            time: null,
          },
        ]);
      }
    });

    socket.on('connect', () => {
      setGameStatus('not-started');
      if (lobbyId !== null) {
        joinLobby({ lobbyId, type: 'tv' });
      }
    });

    socket.on('disconnect', resetState);
    return () => {
      socket.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lobbyId, players, ranks]);

  useEffect(() => {
    if (gameStatus === 'complete' && currentLevels.every((l) => l.level < 2)) {
      gameincomplete();
    }
  }, [currentLevels, gameStatus, gameincomplete]);

  return component({
    name,
    setName,
    email,
    setEmail,
    company,
    setCompany,
    players,
    setPlayers,
    host,
    setHost,
    errorMessage,
    setErrorMessage,
    optionData,
    eventData,
    joinLobby,
    leaveLobby,
    removePlayer,
    gameStatus,
    setGameStatus,
    lobbyId,
    updatePlayerstatus,
    startGame,
    startCountdown,
    setStartCountdown,
    timeLeft,
    currentNumbers,
    setModalOpen,
    modalOpen,
    scoreTimer,
    incorrectAnswer,
    currentLevels,
    ranks,
    setLobbyId,
    stages,
    gameTimerRef,
    resetState,
    resetGame,
    index,
    setIndex,
  });
};

export default SocketWrapper;
