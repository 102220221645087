export function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var numbers = '0123456789';
  var charactersLength = characters.length;
  var numberLength = numbers.length;

  for (var i = 0; i < length; i++) {
    if (Math.random() < 50 / 100) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    } else {
      result += numbers.charAt(Math.floor(Math.random() * numberLength));
    }
  }
  return result;
}
