import { useRef, useEffect } from 'react';
import GameTImer from '../Components/GameTImer';
import TechHeading from '../Components/TechHeading';
import GameCountdown from '../Components/Countdown';

import Logo from '../img/logo.png';
import MainContent from '../Components/Global/MainContent';
import { Video } from '../Components/Video';
import Footer from '../Components/Global/Footer';

const Game = ({
  timeLeft,
  setGameStatus,
  startCountdown,
  setStartCountdown,
  players,
  eventData,
  currentNumbers,
  currentLevels,
  optionData,
  stages,
  ranks,
  gameTimerRef,
  resetGame,
}) => {
  const prevStage = useRef([]);

  useEffect(() => {
    prevStage.current = stages;
  }, [stages]);

  if (startCountdown) {
    return (
      <GameCountdown
        startCountdown={startCountdown}
        setStartCountdown={setStartCountdown}
        timeLeft={timeLeft}
        setGameStatus={setGameStatus}
        optionData={optionData}
        eventData={eventData}
      />
    );
  } else {
    return (
      <>
        <Video layer="bg" />
        <MainContent>
          <GameHeader
            gameTimerRef={gameTimerRef}
            setGameStatus={setGameStatus}
            eventData={eventData}
            timeLeft={timeLeft}
            startCountdown={startCountdown}
          />
          <div className="section-player-list container flex flex-col items-center gap-3">
            {players.map((player) => {
              console.log(player);
              const playerColor = () => {
                if (player.playerNumber === 1) {
                  return 'amethyst';
                }
                if (player.playerNumber === 2) {
                  return 'flamingo';
                }
                if (player.playerNumber === 3) {
                  return 'carnation';
                }
                return 'amethyst';
              };
              const playerDropShadowMd = () => {
                if (player.playerNumber === 1) {
                  return 'drop-shadow-amethyst-md';
                }
                if (player.playerNumber === 2) {
                  return 'drop-shadow-flamingo-md';
                }
                if (player.playerNumber === 3) {
                  return 'drop-shadow-carnation-md';
                }
                return 'drop-shadow-amethyst-md';
              };
              const playerDropShadowSm = () => {
                if (player.playerNumber === 1) {
                  return 'drop-shadow-amethyst-sm';
                }
                if (player.playerNumber === 2) {
                  return 'drop-shadow-flamingo-sm';
                }
                if (player.playerNumber === 3) {
                  return 'drop-shadow-carnation-sm';
                }
                return 'drop-shadow-amethyst-md';
              };

              const stageIndex = stages.findIndex((s) => s.playerNumber === player.playerNumber);
              const levelIndex = currentLevels.findIndex((s) => s.playerNumber === player.playerNumber);
              const rankIndex = ranks.findIndex((s) => s.playerNumber === player.playerNumber);

              const pointOfSequence = stages[stageIndex].stage;
              // const prevPointOfSequence = prevStage.current[stageIndex].stage;

              let position;
              let rank;

              if (ranks[rankIndex] && ranks[rankIndex].rank) {
                if (ranks[rankIndex].rank === '1st') {
                  position = 'wins!';
                  rank = 'first place';
                } else if (ranks[rankIndex].rank === 'lost') {
                  position = "didn't finish";
                  rank = position;
                } else if (ranks[rankIndex].rank === '2nd') {
                  position = ranks[rankIndex].rank;
                  rank = 'second place';
                } else if (ranks[rankIndex].rank === '3rd') {
                  rank = 'third place';
                  position = ranks[rankIndex].rank;
                }
              }

              const setProgressSteps = () => {
                if (currentLevels[levelIndex].level === 0) {
                  return `${Math.round((pointOfSequence / 3) * 100)}%`;
                } else if (currentLevels[levelIndex].level === 1) {
                  return `${Math.round((pointOfSequence / 4) * 100)}%`;
                } else {
                  return `${Math.round((pointOfSequence / 5) * 100)}%`;
                }
              };

              // const time = `${date.getMinutes()}:${date.getSeconds()}:${('0' + Math.round(date.getSeconds())).slice(
              //   -2
              // )}`;

              const date = new Date(ranks[rankIndex].time);

              const time = `0${date.getMinutes()}:${
                date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
              }:${('0' + Math.round(date.getMilliseconds())).slice(-2)}`;

              return (
                <>
                  {ranks[rankIndex] && ranks[rankIndex].rank && (
                    <article className="py-4 text-center">
                      <p className="uppercase text-xl">
                        <span className={`text-${playerColor()}`}>Player {player.playerNumber}: </span>
                        <span className="font-extrabold">{player.name} </span>
                        <span className={`text-${playerColor()}`}>{position}</span>
                      </p>
                      <p className={`text-${playerColor()}`}>
                        <span className={`${playerDropShadowSm()} text-h2 font-bold text-stroke !leading-[0.7]`}>
                          {rank}
                        </span>
                      </p>
                      {ranks[rankIndex].rank !== 'lost' && (
                        <p className="text-h5 mt-2">
                          <span className="font-extrabold">TIME:</span> {time}
                        </p>
                      )}
                    </article>
                  )}
                  {ranks[rankIndex] && !ranks[rankIndex].rank && (
                    <article className="player-list-item w-[1412px] h-[254px] relative">
                      <svg className="absolucte inset-0 w-full h-full z-[0]">
                        <use xlinkHref="#player-ui-bg" />
                      </svg>
                      <svg className={`absolute inset-0 w-full h-full z-[1] drop-shadow-${playerColor()}-lg`}>
                        <use xlinkHref="#player-ui" />
                      </svg>
                      <div className="absolute inset-0 w-full h-full z-[2] flex justify-between">
                        <header className="px-6 py-4 w-[27.7%] flex justify-between flex-col">
                          <div>
                            <TechHeading
                              text={'player ' + player.playerNumber}
                              color={`bg-${playerColor()}`}
                              clipPostion="top-right"
                              classes={`drop-shadow-${playerColor()}-sm`}
                            />
                            <p className="uppercase text-xl font-medium mt-1 whitespace-nowrap text-ellipsis overflow-hidden">
                              {player.name}
                            </p>
                          </div>
                          <div className={`text-${playerColor()}`}>
                            <TechHeading
                              text="level"
                              color={`bg-${playerColor()}`}
                              clipPostion="top-right"
                              classes={`drop-shadow-${playerColor()}-sm`}
                            />
                            <p
                              className={`drop-shadow-${playerColor()}-sm uppercase text-h2 text-stroke font-extrabold !leading-[0.65] mt-2`}
                            >{`0${currentLevels[levelIndex].level + 1}`}</p>
                          </div>
                        </header>
                        <div className="w-[71%] flex items-stretch">
                          <div className="pl-[14%] flex flex-col justify-between">
                            <ul className={`${playerDropShadowMd()} flex grow`}>
                              {currentNumbers.filter((c) => c.playerNumber === player.playerNumber) &&
                                currentNumbers.filter((c) => c.playerNumber === player.playerNumber)[0] &&
                                currentNumbers
                                  .filter((c) => c.playerNumber === player.playerNumber)[0]
                                  .numbers.map((n, key) => {
                                    let id = n;

                                    if (id < 10) {
                                      id = '0' + id;
                                    }

                                    if (pointOfSequence === 0) {
                                      setTimeout(() => {
                                        prevStage.current = stages;
                                      }, 500);
                                    }

                                    return (
                                      <li
                                        key={key}
                                        className={`w-[133px] aspect-[66/82] relative text-${playerColor()}`}
                                      >
                                        <svg
                                          className={`text-white shape-svg w-full h-full transition-drop-shadow duration-500 ease-in-out-quart`}
                                          preserveAspectRatio="xMinYMid meet"
                                        >
                                          <use xlinkHref={`#code-shape-${id}`} />
                                        </svg>

                                        <i
                                          className={`${
                                            pointOfSequence - 1 >= key ? 'opacity-1 scale-1' : 'opacity-0 scale-0'
                                          } transition-all w-[90px] h-[90px] bg-dark rounded-full border-current border-[7px] absolute top-[50%] left-[50%] ml-[-45px] mt-[-45px] flex justify-center items-center`}
                                        >
                                          <svg className="w-[45px] h-45px text-white">
                                            <use xlinkHref={`#tick`} />
                                          </svg>
                                        </i>
                                        {/* <i
                                          className={`${
                                            pointOfSequence === 0 && prevPointOfSequence - 1 >= key
                                              ? 'opacity-1 scale-1'
                                              : 'opacity-0 scale-0'
                                          } transition-all w-[90px] h-[90px] bg-dark rounded-full border-current border-[7px] absolute top-[50%] left-[50%] ml-[-45px] mt-[-45px] flex justify-center items-center`}
                                        >
                                          <svg className="w-[45px] h-45px text-white">
                                            <use xlinkHref={`#cross`} />
                                          </svg>
                                        </i> */}
                                      </li>
                                    );
                                  })}
                            </ul>
                            <TechHeading
                              text={`${setProgressSteps()}`}
                              color={`bg-${playerColor()}`}
                              clipPostion="top-right"
                              classes={`drop-shadow-${playerColor()}-sm mb-[0.3%] ease-in-out transition-all !block min-w-[44px]`}
                              inlineStyle={{ width: setProgressSteps() }}
                            />
                          </div>
                        </div>
                      </div>
                    </article>
                  )}
                </>
              );
            })}
          </div>
          <Footer eventData={eventData} optionData={optionData} resetGame={resetGame} />
        </MainContent>
        <Video layer="fg" />
      </>
    );
  }
};

const GameHeader = ({ timeLeft, setGameStatus, eventData, gameTimerRef, startCountdown }) => {
  return (
    <header className="container flex">
      <figure className="section-sm !w-[153px] shrink-0 mr-20 grow">
        <img className="w-full h-auto" src={Logo} alt="logo" />
      </figure>
      <div>
        <TechHeading text="time remaining" color="bg-white" clipPostion="bottom-right" />
        <div className="mt-4">
          {startCountdown ? (
            <p className="!font-mono font-light text-[49px] text-current">02:00:00</p>
          ) : (
            <GameTImer gameTimerRef={gameTimerRef} timeLeft={timeLeft} setGameStatus={setGameStatus} />
          )}
        </div>
      </div>
      <div className="grow flex flex-col justify-center text-right section-sm">
        <div>
          {eventData && eventData.acf.name && <p className="text-h5 font-extrabold">{eventData.acf.name}</p>}
          {eventData && eventData.acf.details && <p className="text-bold text-xl font-bold">{eventData.acf.details}</p>}
        </div>
      </div>
    </header>
  );
};

export { Game, GameHeader };
