import React from 'react';
import bgVideo from '../vid/noise-bg.mp4';
import fgVideo from '../vid/noise-fg.mp4';

export function Video({ layer }) {
  if (layer === 'bg') {
    return (
      <video
        className="fixed inset-0 w-full h-full object-cover z-0"
        muted
        autoPlay
        playsInline
        loop
        type="video/mp4"
        src={bgVideo}
      />
    );
  } else if (layer === 'fg') {
    return (
      <video
        className="fixed inset-0 w-full h-full object-cover z-[40] mix-blend-screen pointer-events-none"
        muted
        autoPlay
        playsInline
        loop
        type="video/mp4"
        src={fgVideo}
      />
    );
  }
}
