import React, { useEffect, useRef } from 'react';
import { Video } from '../Components/Video';
import MainContent from '../Components/Global/MainContent';
import Footer from '../Components/Global/Footer';

import LogoQrR from '../img/logo-with-qr-background.png';
import QRCode from 'react-qr-code';
import { Loader } from '../Components/Loader';

const Idle = ({ lobbyId, optionData, eventData, resetGame, index, setIndex }) => {
  const timeoutRef = useRef(null);

  const slideItems = document.querySelectorAll('.js-slide');
  var delay = 5000;
  if (optionData && optionData.acf && optionData.acf.general && optionData.acf.general.idle_screen_duration) {
    delay = optionData.acf.general.idle_screen_duration * 1000;
  }
  const cssDelay = delay / 1000;

  const cellClass = 'px-10 py-[6px]';
  const slideClass = 'absolute left-0 top-0 grow w-full h-full';
  const slideActiveClass = 'block';
  const slideInactiveClass = 'hidden';
  // const slideClass = 'grow';

  let scoreboard, scoreboardSorted;
  if (eventData && eventData.acf && eventData.acf.scoreboard) {
    scoreboard = eventData.acf.scoreboard;
    scoreboardSorted = scoreboard.filter((s) => s.score < 120000);
    scoreboardSorted = scoreboardSorted.sort(({ score: a }, { score: b }) => a - b);
    scoreboardSorted = scoreboardSorted.slice(0, 10);
  }

  function getTime(time) {
    const date = new Date(+time);
    const t = `0${date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}:${(
      '0' + Math.round(date.getMilliseconds())
    ).slice(-2)}`;
    return t;
  }

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      // loop through all of the slides, but at last slide, go back to the second slide
      // as we only want to show the first slide once
      () => setIndex((prevIndex) => (prevIndex === slideItems.length - 1 ? 1 : prevIndex + 1)),
      delay
    );
    return () => {};
  }, [index, slideItems.length, delay, setIndex]);
  if (eventData && eventData.acf) {
    return (
      <>
        <Video layer="bg" />
        <MainContent>
          <div className="ani-artifact-glitch-1"></div>
          <div className="flex items-center justify-center w-full h-full relative grow">
            {/*
          !!!!!!!!!!!!!!!!!!!!!!!
          Slide 1
          !!!!!!!!!!!!!!!!!!!!!!!
           */}
            <div
              className={`${
                index === 0 ? slideActiveClass : slideInactiveClass
              } js-slide container section ${slideClass} flex items-center justify-center`}
              style={{ animationDuration: index === 0 ? `${cssDelay}s` : '0s' }}
            >
              <LogoQR width="w-[910px]" height="h-[428px]" textSize="text-qr" lobbyId={lobbyId} />
            </div>
            {/*
          !!!!!!!!!!!!!!!!!!!!!!!
          Slide 2
          !!!!!!!!!!!!!!!!!!!!!!!
           */}
            <div
              className={`${index === 1 ? slideActiveClass : slideInactiveClass} js-slide w-full flex ${slideClass}`}
              style={{ animationDuration: index === 1 ? `${cssDelay}s` : '0s' }}
            >
              <div className="container section flex flex-col justify-between">
                <LogoQR width="w-[412px]" height="h-[194px]" lobbyId={lobbyId} />
                <div className={`grow flex justify-center${scoreboardSorted ? '' : ' items-center'}`}>
                  <div className="mt-2 2xl:mt-6">
                    <header>
                      {scoreboardSorted && <h1 className="text-h4 font-extrabold text-center mt-4">leaderboard</h1>}
                      <p
                        className={`${
                          scoreboardSorted ? 'text-xl' : 'text-3xl'
                        } uppercase text-center max-w-5xl mx-auto`}
                      >
                        {eventData && eventData.acf.name && <span className="font-bold">{eventData.acf.name}</span>}
                        {eventData && eventData.acf.details && <> - {eventData.acf.details}</>}
                      </p>
                    </header>
                    <div className="mt-6 2xl:mt-[4vh] w-full max-w-5xl flex flex-col mx-auto divide-y divide-mid bg-black rounded-lg drop-shadow-white-md">
                      {scoreboardSorted &&
                        scoreboardSorted.map((items, index) => {
                          return (
                            <div key={index} className="text-xl w-full grid grid-cols-12 flex-row justify-between">
                              <span className={`${cellClass} font-bold col-span-5 flex items-center`}>
                                <span className="w-[24px] mr-4">{index + 1}</span>
                                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                                  {items.player[0] && items.player[0].acf.name}
                                </span>
                              </span>
                              <span
                                className={`${cellClass} col-span-4 whitespace-nowrap text-ellipsis overflow-hidden`}
                              >
                                {items.player[0] && items.player[0].acf.company}
                              </span>
                              <span className={`${cellClass} text-right font-bold col-span-3 font-mono`}>
                                {getTime(items.score)}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*
          !!!!!!!!!!!!!!!!!!!!!!!
          Slide 3
          !!!!!!!!!!!!!!!!!!!!!!!
           */}
            <div
              className={`${index === 2 ? slideActiveClass : slideInactiveClass} js-slide flex ${slideClass}`}
              style={{ animationDuration: index === 2 ? `${cssDelay}s` : '0s' }}
            >
              <div className="container section flex flex-col justify-between">
                <LogoQR width="w-[412px]" height="h-[194px]" lobbyId={lobbyId} />
                <div className="grow flex flex-col items-center justify-center">
                  <div className="space-y-14">
                    {eventData.acf.logo && eventData.acf.logo.sizes.medium_large && (
                      <figure className="w-[265px] mx-auto">
                        <img
                          src={eventData.acf.logo.sizes.medium_large}
                          className="w-full h-auto"
                          alt={eventData.acf.logo.alt}
                        />
                      </figure>
                    )}
                    {eventData && eventData.acf.idle_screen_text && (
                      <p className="text-3xl text-center max-w-6xl mx-auto">{eventData.acf.idle_screen_text}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer eventData={eventData} optionData={optionData} resetGame={resetGame} />
        </MainContent>
        <Video layer="fg" />
      </>
    );
  } else {
    return (
      <>
        <Video layer="bg" />
        <MainContent>
          <div className="flex items-center justify-center w-full h-full relative grow">
            <Loader />
          </div>
          <Footer optionData={optionData} />
        </MainContent>
        <Video layer="fg" />
      </>
    );
  }
};

export const LogoQR = ({ width, height, textSize = 'text-base', lobbyId }) => {
  return (
    <div className={`${width} ${height} mx-auto relative text-dark shrink-0`}>
      <img className="w-full h-auto absolute left-0 top-0 z-[1]" src={LogoQrR} alt="logo with QR code" />
      <p className="w-[33%] right-[1%] top-[12%] uppercase font-bold absolute z-[2] text-center">
        <span className={textSize}>scan to play</span>
      </p>
      <figure className="js-qr-container w-[23%] aspect-square absolute right-[6.1%] top-[25.4%] block z-[2] flex items-center justify-center">
        <QRCode className="absolute w-full h-full inset-0" value={`https://app.britcodehacker.co.uk/${lobbyId}`} />
      </figure>
      <p className="w-[33%] right-[1%] bottom-[11.6%] uppercase font-bold absolute z-[2] text-center">
        <span className={textSize}>hack and win</span>
      </p>
    </div>
  );
};

export default Idle;
