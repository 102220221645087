import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';

const renderer = ({ hours, minutes, seconds, milliseconds, completed }) => {
  // console.log()
  return (
    <p className="!font-mono font-light text-[49px] text-current">
      {zeroPad(minutes)}:{zeroPad(seconds)}:{zeroPad(milliseconds / 10)}
    </p>
  );
};

const GameTImer = React.memo((props) => {
  return (
    <Countdown
      date={Date.now() + props.timeLeft * 1000}
      intervalDelay={40}
      precision={2}
      ref={props.gameTimerRef}
      renderer={renderer}
    />
  );
});

export default React.memo(GameTImer);
