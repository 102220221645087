import React, { useEffect } from 'react';

import { Video } from '../Components/Video';
import MainContent from '../Components/Global/MainContent';
import Logo from '../img/logo.png';
import { useNavigate } from 'react-router-dom';

const GameSelect = ({ joinLobby, setLobbyId, eventsData }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const gameId = searchParams.get('gameId');
  const navigate = useNavigate();

  useEffect(() => {
    if (gameId) {
      navigate(`/${gameId}`);
    }
  }, [gameId, navigate]);

  return (
    <>
      <Video layer="bg" />
      <MainContent>
        <div className="flex items-center justify-center w-full h-full relative flex-col grow space-y-6">
          <figure className="w-[200px]">
            <img className="w-full h-auto" src={Logo} alt="logo" />
          </figure>
          <p className="tracking-widest uppercase">select a game</p>
          <ul className="space-y-4">
            {eventsData.map((l, key) => {
              return (
                <li key={key}>
                  <a className="flex items-end group" href={`/${l.id}`}>
                    <span className="font-bold mr-1 mb-1 text-mid">{key.toString().padStart(2, '0')}.</span>
                    <span className="text-xl uppercase group-hover:text-hotpink transition-colors">{l.acf.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </MainContent>
      <Video layer="fg" />
    </>
  );
};

export default GameSelect;
