const WPAPI = require('wpapi');

const ep = process.env.REACT_APP_WP_URL || 'https://cms.britcodehacker.co.uk/wp-json';
const wp = new WPAPI({
  endpoint: ep,
  username: process.env.REACT_APP_WP_LOGIN_USER,
  password: process.env.REACT_APP_WP_LOGIN_PASS,
});

// wp.setHeaders('Authorization', 'https://tv.britcodehacker.co.uk');

wp.player = wp.registerRoute('wp/v2', 'player/(?P<id>\\d+)');
wp.playerAcf = wp.registerRoute('acf/v3', 'player/(?P<id>\\d+)');
wp.eventAcf = wp.registerRoute('acf/v3', 'event/(?P<id>\\d+)', { params: ['timestamp'] });
wp.event = wp.registerRoute('wp/v2', 'event/(?P<id>\\d+)');
wp.options = wp.registerRoute('acf/v3', 'options/(?P<id>\\d+)');

const getEventById = (eventId) => wp.eventAcf().timestamp(Date.now()).id(eventId).get();
export async function getEventData(lobbyId) {
  const [event] = await Promise.all([getEventById(lobbyId)]);
  return event;
}

// const getEvents = () => wp.event();
export async function getAllEventData() {
  const [events] = await Promise.all([wp.eventAcf()]);
  return events;
}

export async function getOptionsData() {
  const [options] = await Promise.all([wp.options()]);
  return options;
}
